<script setup lang="ts">
import PbCircularLoader from "@/ContextTab/components/Busy/Loader/Circular.vue"
import PsDateWidget from "@/ContextTab/components/Date/DateWidget.vue"
// import PbEntryCodes from "@/ContextTab/pskit-ui/PbEntry/PbEntryCodes.vue"
import { ref, toRef, computed, onBeforeMount, nextTick, watch } from "vue"
import useEntityTooltip from "@/ContextTab/modules/news/composables/useEntityTooltip"
import { formatUsername } from "@/utils/formatUsername"
// import useKeyboardScroll from '@/modules/News/common/composers/useKeyboardScroll'

const props = withDefaults(
  defineProps<{
    newsItem: any
    version: any
    isCompact?: boolean
    searchMode?: boolean
  }>(),
  {
    isCompact: false,
    searchMode: false,
  }
)

//   const isFocusable = computed(() => {
//     // TODO: расширить логику, этот же компонент используется в панели type=search
//     return store.getters['panels/focusedPanel'].type === 'published'
//   })

const content = ref(null)

const { markupEntities } = useEntityTooltip(
  toRef(() => props.version),
  content
)
onBeforeMount(() => {
  nextTick(() => {
    markupEntities()
  })
})
//   useKeyboardScroll(content, isFocusable)

// const isCodesExpanded = ref(false)

const authors = computed(() => {
  return props.version.authors
    .map(
      (author: any) =>
        `${formatUsername(author.user.name)} (${author.user.asoi_name})`
    )
    .join(" / ")
})

const editors = computed(() => {
  const publisher = props.newsItem.publisher
  return `${formatUsername(publisher.name)} (${publisher.asoi_name})`
})

watch(
  () => props.newsItem,
  (next, prev) => {
    if (next.id !== prev.id) {
      // isCodesExpanded.value = false
      // content.value.scrollTop = 0

      nextTick(() => {
        markupEntities()
      })
    }
  }
)

// const handleCodesToggle = state => {
//   isCodesExpanded.value = state
// }
</script>

<template>
  <div class="published-item-view" :class="{ 'search-hightlight': searchMode }">
    <div ref="content" class="content" tabindex="-1">
      <PbCircularLoader
        v-if="!newsItem || !version || !newsItem.id || !version.id"
        :size="30"
      />

      <div v-else class="content-inner">
        <div v-if="newsItem.status_modified_at" class="date">
          <PsDateWidget :date="newsItem.status_modified_at" :onlyTime="false" />
        </div>
        <div
          v-if="version.slugline"
          class="slugline"
          v-html="version.slugline"
        ></div>

        <h3
          v-if="version.headline"
          class="headline"
          v-html="version.headline"
        ></h3>
        <div v-if="version.authors" class="author">
          <span>Автор: </span>
          <span class="highlight" data-test-selector="authors-list">
            {{ authors }}
          </span>
        </div>
        <div v-if="newsItem.publisher" class="editor">
          <span>Редактор: </span>
          <span class="highlight" data-test-selector="editors-list">
            {{ editors }}
          </span>
        </div>
        <div
          v-if="version.subheadline"
          class="subheadline"
          v-html="version.subheadline"
        ></div>
        <div
          v-if="version.trashline"
          class="trashline"
          v-html="version.trashline"
        ></div>
        <div
          v-if="version.dateline"
          class="dateline"
          v-html="version.dateline"
        ></div>
        <div v-if="version.body" class="body" v-html="version.body"></div>
        <div
          v-if="version.background"
          class="background"
          v-html="version.background"
        ></div>
      </div>
    </div>

    <!-- <div
      v-if="version.codes"
      :key="newsItem.id"
      class="codes"
    >
      <transition name="fade-in">
        <NewsItemCodes
          v-show="isCodesExpanded"
          class="codesDetails"
          :news-item-id="newsItem.id"
          :is-editable="false"
          :version="version"
          :code-items="codeItems"
          :iptc-code-id="iptcCodeId"
          :news-codes="newsCodes"
          :color-scheme="{ groupColor: '000', codeColor: '0069e4' }"
        />
      </transition>
      <PbEntryCodes
        :key="newsItem.id"
        :codes="version.codes"
        :preferable-group-ids="preferableCodeGroupIds"
        :compact="isCompact"
        :collapse-on-expanded="true"
        expandable
        @expandEntryCodes="handleCodesToggle"
      />
    </div> -->
  </div>
</template>

<style lang="postcss" scoped>
.published-item-view {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.content {
  position: relative;
  overflow-y: auto;
  flex-grow: 1;
  flex-shrink: 1;

  &:focus::-webkit-scrollbar-thumb {
    background: #5b5f687a !important;
  }

  &:focus::before {
    content: "";

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 0;

    border-top: 2px solid #0069e4;
    border-right: 0;
  }
}

.content-inner {
  padding: 8px 16px;

  & span[data-entity-id] {
    display: inline-block;
    text-indent: 0;
  }
}

.codes {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  padding: 8px 16px;
  margin-top: auto;
  background-color: #f2f2f2;
  box-shadow: 0px -10px 20px rgba(0, 0, 0, 0.08);
}

.codesDetails {
  overflow-y: auto;
  margin: -8px -16px 8px;
  padding: 0 16px;
}

.headline {
  margin-bottom: 12px;
  font-size: 21px;
  font-weight: bold;
  line-height: 1;
}

.date,
.author,
.editor {
  margin-bottom: 4px;
  font-size: 12px;
  line-height: calc(14 / 12);
}

.editor {
  margin-bottom: 12px;
}

.subheadline {
  margin-bottom: 12px;
  font-style: italic;
  font-size: 16px;
  line-height: calc(18 / 16);
}

.slugline,
.dateline,
.trashline {
  margin-bottom: 12px;
}

.dateline {
  display: inline;
  text-indent: 1rem;
}

.body {
  display: inline;
  margin-bottom: 12px;
  font-size: 16px;
  line-height: calc(19 / 16);
}

.background {
  font-size: 16px;
  line-height: calc(19 / 16);
}

.highlight {
  color: #0064d9;
}
</style>

<style lang="postcss">
@import "@/ContextTab/modules/news/published/styles/search-result-hightlight.pcss";

.published-item-view {
  .body,
  .background {
    & p {
      text-indent: 1rem;
    }

    & p:first-child {
      display: inline;
      &:before {
        content: "\0020";
      }
    }

    & table {
      margin: 1rem 0;
      border-collapse: collapse;

      & p {
        text-indent: unset;
      }
    }

    & td {
      padding: 0.5rem;
      border: 1px solid #666;
    }
  }
}
</style>
