<script setup lang="ts">
import PsButton from "@/ContextTab/components/UI/Button/PsButton.vue"
import PsIcon from "@/ContextTab/components/UI/PsIcon.vue"
import { ref } from "vue"

const emit = defineEmits(["update"])

const isWorking = ref(false)

const update = () => {
  isWorking.value = true
  emit("update")
}
</script>

<template>
  <div class="update-service-worker">
    <div class="wrapper" :class="{ working: isWorking }">
      <div class="message">
        <span v-if="isWorking">Обновляем...</span>
        <span v-else>Вышла новая версия Издательской системы</span>
      </div>
      <div v-show="!isWorking" class="action">
        <PsButton
          size="md"
          color="light"
          :outline="true"
          :padding="false"
          class="update-button"
          @click="update"
        >
          <template #prefix>
            <PsIcon type="font" name="refresh" class="icon" />
          </template>
          Обновить
        </PsButton>
      </div>
    </div>
  </div>
</template>

<style scoped>
@keyframes progress-bar-stripes {
  from {
    background-position: 24px 0;
  }
  to {
    background-position: 0 0;
  }
}

.update-service-worker {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  font-size: var(--font-size-md);
  color: #fff;
  z-index: 1010;

  .wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: auto;
    margin: 0 auto;
    min-width: min(460px, 100vw);
    min-height: 3rem;
    padding: var(--padding-top-1-2) var(--padding-left);
    background: #4caf50;
    border-radius: 0 0 4px 4px;
    box-shadow:
      0 3px 5px -1px rgba(0, 0, 0, 0.2),
      0 6px 10px 0 rgba(0, 0, 0, 0.14),
      0 1px 18px 0 rgba(0, 0, 0, 0.12);

    z-index: 1010;

    &.working {
      background-color: #4caf50;
      text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
      background-image: linear-gradient(
        45deg,
        rgba(255, 255, 255, 0.15) 25%,
        transparent 25%,
        transparent 50%,
        rgba(255, 255, 255, 0.15) 50%,
        rgba(255, 255, 255, 0.15) 75%,
        transparent 75%,
        transparent
      );
      background-size: 24px 24px;
      animation: progress-bar-stripes 1s linear infinite;
    }

    .message {
      color: #fff;
      pointer-events: none;
      text-align: center;
/*      line-height: 28px;*/
      flex-grow: 1;
    }
    .action {
      flex-shrink: 0;
      margin-left: 20px;
    }
    .update-button {
      height: 28px;
      padding: 0 8px 0 4px;
/*      background: rgba(#fff, 0.2);*/
/*      border-radius: 2px;*/

      .icon {
        animation: rotate-refresh-icon 15s
          cubic-bezier(0.455, 0.03, 0.515, 0.955) 5s infinite both;
      }
    }
  }
}

@keyframes rotate-refresh-icon {
  0% {
    transform: rotate(0);
  }
  7% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
