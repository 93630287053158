<script setup lang="ts">
import PsSumbitGroup from "@/ContextTab/components/PsSubmitGroup.vue"
import PsModal from "@/ContextTab/components/UI/PsModal.vue"
import { ref, watch } from "vue"

type ActiveButton = "submit" | "cancel" | undefined

const props = withDefaults(
  defineProps<{
    agreeLabel?: string
    declineLabel?: string
    initActiveButton?: ActiveButton
  }>(),
  {
    agreeLabel: "Да",
    declineLabel: "Нет",
    initActiveButton: undefined,
  }
)

const emit = defineEmits(["update:modelValue", "submit", "cancel"])

const show = defineModel<boolean>({ default: false })

const active = ref(props.initActiveButton)

watch(show, val => {
  // TODO: внедрить глобальный обработчик нажатия клавиш
  val
    ? document.addEventListener("keyup", onKeyPress)
    : document.removeEventListener("keyup", onKeyPress)
})

const submit = () => {
  emit("submit")
  close()
}

const cancel = () => {
  emit("cancel")
  close()
}

const close = () => {
  document.removeEventListener("keyup", onKeyPress)
  show.value = false
}

const onKeyPress = e => {
  if (!props.initActiveButton) {
    return
  }

  if (e.key === "Enter") {
    active.value === "submit" ? submit() : cancel()
  }

  if (e.key === "ArrowRight" || e.key === "ArrowLeft") {
    active.value = active.value === "submit" ? "cancel" : "submit"
  }
}
</script>

<template>
  <PsModal v-model:show="show" class="confirm-modal" size="sm">
    <div class="confirm-modal__content">
      <div class="confirm-modal__question"><slot name="question" /></div>
      <footer class="confirm-modal__footer">
        <PsSumbitGroup
          :submitLabel="agreeLabel"
          :cancelLabel="declineLabel"
          :activeButton="active"
          buttonWidth="80px"
          @submit="submit"
          @cancel="cancel"
        />
      </footer>
    </div>
  </PsModal>
</template>

<style lang="postcss" scoped>
.confirm-modal__content {
  text-align: center;
}
.confirm-modal__question {
  font-weight: 600;
  padding: var(--padding-top) 0 var(--padding-bottom-2) 0;
}
.confirm-modal__footer {
  display: flex;
  justify-content: center;
}
</style>
