import PsTooltip from "@/ContextTab/components/UI/Popover/PsTooltip.vue"
import { mount } from "mount-vue-component"
import { h, type Ref } from "vue"

export default function (versionRef: Ref, contentainerRef: Ref) {
  const markupEntities = () => {
    if (!versionRef.value.entities) return

    const entities = versionRef.value.entities.map((item: any) => item.entity)
    const entityNodes: HTMLElement[] = contentainerRef.value.querySelectorAll(
      "span[data-entity-id]"
    )

    entityNodes.forEach(node => {
      const id = parseInt(node.getAttribute("data-entity-id") ?? "", 10)
      const entity = entities.find((entity: any) => entity.id === id)
      if (!entity) return

      // при поиске по сущностям внутрь добавляется элемент <em> для того, чтобы выделить поисковый запрос
      const hasChildNodes = node.children.length

      const text = node.innerText
      node.innerText = ""

      mount(PsTooltip, {
        props: {
          content: `${Object.values(entity.properties).map(
            value => `${value} — `
          )}${entity.name}`,
        },
        children: () => [
          h(
            "span",
            {
              class: "_text-color:primary",
            },
            hasChildNodes ? h("em", text) : text
          ),
        ],
        element: node as HTMLElement,
      })
    })
  }

  return { markupEntities }
}
