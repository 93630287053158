import { registerSW } from "virtual:pwa-register"
import { ref, type Ref } from "vue"
import { messageTypes } from "@/lib/messageHub/messages"
import type MessageHubClient from "@/lib/messageHub/client"

class AppUpdate {
  private _updateSW
  hasUpdate: Ref<boolean> = ref(false)

  constructor() {
    this._updateSW = registerSW({
      onNeedRefresh: () => {
        this.hasUpdate.value = true
      },
      onRegistered: (reg: ServiceWorkerRegistration) => {
        // Проверяем, есть ли обновления раз в 15 минут
        reg && setInterval(() => {
          reg.update()
        }, 1000 * 60 * 15)
      }
    })

    this.init = this.init.bind(this)
    this.update = this.update.bind(this)
  }

  init(messageHub: MessageHubClient) {
    messageHub.addMessageListener({
      metaFilter: (meta: any) =>
        messageTypes.byId(meta.typeId).name === messageTypes.names.service &&
        meta.signal === "updateApp",
      callback: () => this._updateSW(),
    })
  }

  update() {
    this._updateSW()
  }
}

export default new AppUpdate()
